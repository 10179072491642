import React, { useEffect, useState } from "react";
import { supabaseClient } from "../config/supabase";
import EquipoCard from "./EquipoCard";
import { motion, AnimatePresence } from "framer-motion";

const EquipoExpandido = () => {
  const [equipoData, setEquipoData] = useState([]);
  const [bioActivaId, setBioActivaId] = useState(null);

  useEffect(() => {
    const fetchEquipo = async () => {
      const { data, error } = await supabaseClient
        .from("equipo")
        .select("*")
        .order("id", { ascending: true });
      if (error) console.log("Error fetching equipo data:", error.message);
      else setEquipoData(data);
    };
    fetchEquipo();
  }, []);

  const handleToggleBio = (id) => {
    setBioActivaId(bioActivaId === id ? null : id);
  };

  return (
    <div className="bg-black min-h-screen md:min-h-[50vh] relative overflow-hidden flex flex-col justify-center px-4 pb-6 pt-16">
      <div className="flex flex-col md:flex-row">
        <div className="basis-1/4">
          <h6 className="text-h6-desktop text-pink26N font-ibm-plex">Equipo</h6>
        </div>
        <div className="basis-3/4">
          <h2 className="text-white font-druk-wide font-bold uppercase text-2xl md:text-4xl">
            Somos lo que contamos
          </h2>
          <motion.div
            className="flex flex-wrap items-start mt-6 gap-x-4 gap-y-6"
            layout // Hace que los elementos se reorganicen suavemente
          >
            {equipoData.map((miembro) => {
              const isExpanded = bioActivaId === miembro.id;

              return (
                <motion.div
                  key={miembro.id}
                  className={`flex flex-wrap w-full md:w-auto ${
                    isExpanded ? "md:flex-nowrap" : "md:w-1/4"
                  }`}
                  layout
                  onClick={() => handleToggleBio(miembro.id)}
                >
                  {/* Tarjeta del equipo */}
                  <motion.div
                    className="flex-shrink-0 w-full md:w-1/3 cursor-pointer transition-all duration-300"
                    layout
                  >
                    <EquipoCard
                      nombre={miembro.nombre}
                      puesto={miembro.puesto}
                      image={miembro.img_url}
                      dark
                      showName={!isExpanded}
                    />
                  </motion.div>

                  {/* Bio insertada dinámicamente */}
                  <AnimatePresence>
                    {isExpanded && (
                      <motion.div
                        className="w-full md:w-2/3 bg-purple-600 text-white p-6 rounded-md min-h-[200px]"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 50 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        layout
                      >
                        <h5 className="font-ibm-plex text-2xl md:text-h5-desktop text-gray126N">
                          {miembro.nombre}
                        </h5>
                        <p className="font-ibm-plex text-gray26N text-p-desktop leading-tight mt-2">
                          {miembro.puesto}
                        </p>
                        <p className="text-md text-white font-ibm-plex mt-6">
                          {miembro.bio}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              );
            })}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default EquipoExpandido;
